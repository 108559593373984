export const calculationFee = (
  detailInformation,
  generalInformation,
  list_form_chi_phi
) => {
  const {
    assistantFee,
    phi_xang_dau,
    phi_cau_duong,
    ve_cau_pha,
    phi_boc_xep,
    phi_sua_chua,
    luong_lai_xe,
    chi_phi_khac,
    luong_truc,
    phu_cap_dien_thoai,
    tham_nien
  } = generalInformation;

  let tong_chi_phi =
    Number(assistantFee) +
    Number(phi_xang_dau) +
    Number(phi_cau_duong) +
    Number(ve_cau_pha) +
    Number(phi_boc_xep) +
    Number(phi_sua_chua) +
    Number(luong_lai_xe) +
    Number(luong_truc) +
    Number(phu_cap_dien_thoai) +
    Number(tham_nien) +
    Number(chi_phi_khac);

  list_form_chi_phi.forEach(fee => {
    tong_chi_phi += Number(fee.value);
  });

  let tong_cuoc = 0;
  detailInformation.map(info => (tong_cuoc += Number(info.transportFee)));

  const tong_lai = Number(tong_cuoc) - Number(tong_chi_phi);
  return { tong_chi_phi, tong_cuoc, tong_lai };
};
