import * as actionTypes from 'actions/tripActions';
import { roundTimeQuarterHourWithDate } from 'utils/format';
function roundTimeQuarterHour() {
  var timeToReturn = new Date();

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return timeToReturn;
}

const initDetailInformation = {
  id_don_hang: '',
  senderName: '',
  receiverName: '',
  senderNumber: '1',
  senderAddress: '',
  receiverAddress: '',
  receiverNumber: '1',
  companyName: '',
  tripType: '',
  itemType: '',
  itemDescription: '',
  itemWeight: '',
  itemNumber: '',
  itemCBM: '',
  inputItemPlate: '',
  inputItemTime: roundTimeQuarterHour(),
  outputItemPlate: '',
  outputItemTime: roundTimeQuarterHour(),
  cod: '',
  transportFee: 0,
  paymentStatus: '',
  paymentType: '',
  collector: '',
  payerMoney: '',
  vat: false,
  note: '',
  images: [],
  ten_khach_hang: '',
  don_gia_don_hang: '',
  don_gia_thau_phu: '',
  rot_diem: '',
  ma_lt: '',
  dien_giai: '',
  nguoi_thuc_hien: '',
  km_theo_app: '',
  km_tinh_lai_xe: '',
};

const initValidateDetail = {
  senderName: false,
  receiverName: false,
  assistantName: false,
  tripType: false,
  itemType: false,
  itemDescription: false,
  itemWeight: false,
  inputItemPlate: false,
  outputItemPlate: false,
  validateDetail: []
};

const initValidate = {
  assistantName: false,
  assistantFee: false,
  ...initValidateDetail
};

const initSuggestions = {
  bien_so_xe: [],
  ten_lai_xe: [],
  ten_nguoi_gui: [],
  sdt_nguoi_gui: [],
  dia_chi_nguoi_gui: [],
  ten_nguoi_nhan: [],
  sdt_nguoi_nhan: [],
  dia_chi_nguoi_nhan: [],
  ten_thau_phu: [],
  loai_hang_hoa: [],
  mo_ta_hang_hoa: [],
  trong_luong: [],
  so_luong: [],
  cbm: [],
  noi_lay_hang: [],
  noi_tra_hang: [],
  cod: [],
  cuoc_van_chuyen: [],
  nguoi_thu_tien: [],
  nguoi_thanh_toan: [],
  ghi_chu: []
};

const initialState = {
  activeStep: 0,
  completed: {},
  generalInformation: {
    kho_chi_dinh: '',
    diem_ket_thuc: '',
    assistantName: '',
    assistantFee: 0,
    checkType: false,
    numberDriver: 2,
    plate: '',
    noi_dung_nhac: '',
    gio_nhac: roundTimeQuarterHour(),
    totalFee: 0,
    tong_km: 0,
    don_gia: 0,
    driver1: '',
    ten_lai_xe_1: '',
    ten_lai_xe_2: '',
    ten_lai_xe_3: '',
    driver2: '',
    driver3: '',
    phi_xang_dau: 0,
    phi_cau_duong: 0,
    ve_cau_pha: 0,
    phi_boc_xep: 0,
    phi_sua_chua: 0,
    luong_lai_xe: 0,
    chi_phi_khac: 0,
    phu_cap_dien_thoai: 0,
    luong_truc: 0,
    tham_nien: 0,
    km_dau: 0,
    km_cuoi: 0,
    ly_do: '',
    ghi_chu_tinh_cuoc: '',
    dinh_muc_dau: '',
    history_tong_km_co_hang_dieu_dong: [],
    history_tong_km_khong_hang_dieu_dong: [],
    history_tong_lit_rong_dieu_dong: [],
    history_tong_lit_co_hang_dieu_dong: [],
    type_cont: false,
    container_xuat: false,
    ma_cont: '',
    ma_seal: '',
    ma_romoc: '',
    thoi_diem_cat_romoc: roundTimeQuarterHourWithDate('1-1-2023'),
    thoi_diem_bat_dau_lai: roundTimeQuarterHourWithDate('1-1-2023'),
    xe_thay_the_bat_dau: roundTimeQuarterHourWithDate('1-1-2023'),
    thoi_gian_boc_cong: roundTimeQuarterHourWithDate('1-1-2023'),
    bien_so_thay_the: '',
    ten_lai_xe_thay_the: '',
    kho_container_lay: '',
    ngay_gio_toi_diem_dong_hang: roundTimeQuarterHourWithDate('1-1-2024'),
    ngay_gio_ket_thuc_tra_hang: roundTimeQuarterHourWithDate('1-1-2024'),
    ngay_gio_toi_diem_ket_thuc: roundTimeQuarterHourWithDate('1-1-2024'),
    ngay_thuc_hien: roundTimeQuarterHourWithDate('1-1-2024'),
    so_km_khong_hang_tron_ve: 0,
    so_km_co_hang_tron_ve: 0,
    quydinh: '',
    dia_diem_giao_nhan: '',
    list_diem_dong_khac: [],
  },
  images: [],
  detailChoose: 0,
  detailInformation: [initDetailInformation],
  list_form_chi_phi: [],
  validate: initValidate,
  suggestion: initSuggestions,
  orderImport: {
    open: false
  },
  listKhachGui: [],
  listKho: [],
  listKhoPP: []
};

const tripReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GENERAL_INFO: {
      return {
        ...state,
        generalInformation: {
          ...state.generalInformation,
          ...action.data
        },
        validate: { ...state.validate, [Object.keys(action.data)[0]]: false }
      };
    }

    case actionTypes.SET_COST_INFO: {
      const newList = state.list_form_chi_phi.map(item => {
        if (item.key === action.data.key) return action.data;
        return item;
      });

      return {
        ...state,
        list_form_chi_phi: newList
      };
    }

    case actionTypes.SET_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.activeStep
      };
    }

    case actionTypes.SET_COMPLETED: {
      return {
        ...state,
        completed: action.completed
      };
    }

    case actionTypes.SET_COST_FORM: {
      return {
        ...state,
        list_form_chi_phi: action.list_form_chi_phi
      };
    }

    case actionTypes.ADD_ORDER: {
      return {
        ...state,
        detailInformation: [...state.detailInformation, initDetailInformation],
        detailChoose: state.detailInformation.length,
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.IMPORT_ORDER: {
      return {
        ...state,
        detailInformation: [...state.detailInformation, ...action.orders],
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.UPDATE_DETAIL_INFO: {
      const newInfo = state.detailInformation.map((data, key) => {
        if (key === state.detailChoose) {
          return { ...data, ...action.data };
        }
        return data;
      });
      return {
        ...state,
        detailInformation: newInfo,
        validate: { ...state.validate, [Object.keys(action.data)[0]]: false }
      };
    }

    case actionTypes.SET_DETAIL_CHOOSE: {
      return {
        ...state,
        detailChoose: action.data,
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.UPDATE_VALIDATE: {
      return {
        ...state,
        validate: { ...state.validate, ...action.data }
      };
    }

    case actionTypes.UPDATE_ORDER_IMPORT: {
      return {
        ...state,
        orderImport: { ...state.orderImport, ...action.data }
      };
    }

    case actionTypes.DELETE_ORDER: {
      return {
        ...state,
        detailInformation: state.detailInformation.filter(
          (value, key) => key !== state.detailChoose
        ),
        detailChoose: 0,
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.SET_DETAIL_INFO: {
      return {
        ...state,
        detailInformation: action.detailInformation,
        generalInformation: action.generalInformation,
        list_form_chi_phi: action.list_form_chi_phi,
        listKhachGui: action.listKhachGui
      };
    }

    case actionTypes.UPDATE_LIST_SENDER: {
      return {
        ...state,
        listKhachGui: action.data
      };
    }

    case actionTypes.DUPLICATE_ORDER: {
      const newInfo = state.detailInformation.find(
        (value, key) => key === state.detailChoose
      );
      return {
        ...state,
        detailInformation: [
          ...state.detailInformation,
          { ...newInfo, id_don_hang: '' }
        ],
        validate: { ...state.validate, ...initValidateDetail }
      };
    }

    case actionTypes.RESET_TRIP: {
      return initialState;
    }

    case actionTypes.SET_SUGGEST: {
      return {
        ...state,
        suggestion: action.suggestion
      };
    }
    case actionTypes.SET_LIST_KHO: {
      return {
        ...state,
        listKho: action.listKho,
        listKhoPP: action.listKhoPP
      };
    }

    default: {
      return state;
    }
  }
};

export default tripReducer;
